<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item v-if="canSave">
            <a-button icon="plus" type="primary" @click="showEditModal()">新增优惠券</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>优惠券名称</span>
              </template>
              <a-input allowClear v-model.trim="searchData.coupon_title" placeholder="优惠券名称" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
              <a-select v-model='searchData.coupon_send_studio_ids' style="width: 200px" mode="multiple" :maxTagCount="1" :maxTagTextLength='5'  allowClear showSearch placeholder="请选择适用校区" :filterOption="filterOption">
              <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
              </a-select>
          </a-form-item>

          <a-form-item>
            <a-radio-group :value="searchData.coupon_status">
              <a-radio-button v-for="(d, index) of couponStatus" :key="index" :value="d['value']" @click="handleTypeButChange(d,'coupon_status')">
                  {{ d['label'] }}
              </a-radio-button>
            </a-radio-group>

            <!-- <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>状态</span>
              </template>
              <a-select allowClear v-model="searchData.coupon_status" placeholder="请选择状态" style="width: 160px">
                <a-select-option v-for="(d, index) of couponStatus" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
              </a-select>
            </a-tooltip> -->
          </a-form-item>

          

          <a-form-item v-if="canIndex">
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="coupon_id"
        :columns="columns" :dataSource="list">
        
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>

          <template slot="coupon_takes" slot-scope="text, record">
            <div @click="showStudentModal(record)" style="cursor:pointer;color:#00cca2">{{text}}</div>
          </template>

          <template slot="coupon_send_studio_names" slot-scope="text, record">
              <span v-if="record.coupon_send_studio_names.length === 0">--</span>
              <a-dropdown v-else>
                  <div style="cursor:pointer" class="ant-dropdown-link" @click="e => e.preventDefault()">
                      {{record.coupon_send_studio_names[0]}}等{{record.coupon_send_studio_names.length}}个校区<a-icon type="caret-down" />
                  </div>
                  <a-menu style="max-height:400px;overflow-y: auto;" slot="overlay">
                      <a-menu-item style="text-align: center" v-for="(it,j) in record.coupon_send_studio_names" :key="j">
                          {{it}}
                      </a-menu-item>
                  </a-menu>
              </a-dropdown>
          </template>

          <template slot="time" slot-scope="text, record">
            <div>{{record.coupon_start_time}}</div>
            <div>{{record.coupon_end_time}}</div>
          </template>

          <template slot="coupon_status_name" slot-scope="text">
            <a-tag :color="text == '有效'?'green':'silver'">{{text}}</a-tag>
          </template>

          <!-- <template slot="action" slot-scope="text,record" >
            <a href="javascript:;" @click="showGrantEditModal(record)"></a>
            <a-divider v-if="canUpdate" type="vertical" />
            <a v-if="canUpdate" href="javascript:;" @click="showEditModal(record)"></a>
            <a-divider v-if="canUpdate && record.coupon_status" type="vertical" />
            <a v-if="canDelete && record.coupon_status" href="javascript:;" @click="deleteItem(record)">作废</a>
          </template> -->

          <template slot="action" slot-scope="text,record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">操作<a-icon type="down" /></a>
              <a-menu slot="overlay">
                <a-menu-item v-if="canUpdate" key="1" @click="showGrantEditModal(record)">
                  发放
                </a-menu-item>
                <a-menu-item key="2"   v-if="canUpdate">
                  <a href="javascript:;" @click="showEditModal(record)">编辑</a>
                </a-menu-item>
                <a-menu-item v-if="canUpdate" key="4" @click="showEditModal(record,1)">
                  复制
                </a-menu-item>
                <a-menu-item v-if="canUpdate && record.coupon_status" key="3" @click="deleteItem(record,1)">
                  作废
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </template>


      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
    <editModal v-if="editVisible" :isEdit='isEdit' :isCopy='isCopy' :item="modalData"/>
    <studentModal v-if="isStudentModal" :item="modalData"/>
    <grantEditModal v-if="isGrantEditModal" :item="modalData"/>
    <copyModal v-if="isCopyModal" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:55, scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '优惠券名称', dataIndex: 'coupon_title', key: 'name' , scopedSlots: { customRender: 'coupon_takes' }},
  { title: '优惠券类型', dataIndex: 'coupon_type', key: '1' },
  { title: '优惠码', dataIndex: 'coupon_code', key: '2' ,align:'center'},
  { title: '发放数', dataIndex: 'coupon_takes', key: '3' ,align:"center", scopedSlots: { customRender: 'coupon_takes' }},
  { title: '适用校区', dataIndex: 'coupon_send_studio_names', key: 'coupon_send_studio_names', scopedSlots: { customRender: 'coupon_send_studio_names' },align:'center'},
  { title: '状态', dataIndex: 'coupon_status_name', key: '4' , scopedSlots: { customRender: 'coupon_status_name' }},
  { title: '添加人', dataIndex: 'created_by', key: '5.6' },
  { title: '有效时间', dataIndex: 'time', key: 'time' ,scopedSlots: { customRender: 'time' }},
  { title: '添加时间', dataIndex: 'created_at', key: '5'},
  { title: '操作', key: 'operation', fixed: 'right', width: 80, scopedSlots: { customRender: 'action' }},
]

import C_ITEMS from '@/utils/items'
import editModal from './editModal'
import studentModal from './studentModal'
import grantEditModal from './grantEditModal'
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table'


export default {
  name: 'gjjl',
  provide() {
    return {
      parent: this
    }
  },

  components: {
    editModal,
    studentModal,
    grantEditModal
  },

  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      studios: [],
      current: 1,
      couponStatus: C_ITEMS.couponStatus,
      searchData: {},
      isEdit:0,
      isCopy:0,
      isCopyModal:false,
      visible: false,
      modalData: {},
      editVisible: false,
      isStudentModal: false,
      isGrantEditModal: false,
      authType:['market','course_fee'],
    }
  },
  mixins: [ authority, tableMixins ],
  created () {
    this.getStudio()
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    closeSearch() {
      this.visible = false
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          obj[k] = searchData[k]
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('courseCouponAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    showEditModal(item,num) {
      if(item){
        if(num){
          this.isCopy = 1
          this.isEdit = 0
        }else{
          this.isCopy = 0
          this.isEdit = 1
        }
      }else{
        this.isEdit = 0
        this.isCopy = 0
      }
      this.modalData = item
      this.editVisible = true
    },
    handleTypeButChange(e,name){
      if(this.searchData[name] == e.value){
        this.searchData[name] = undefined
      }else{
        this.searchData[name] = e.value
      }
      this.searchList()
    },
    showGrantEditModal(item){
      this.modalData = item
      this.isGrantEditModal = true
    },
    showStudentModal(item){
      console.log(item)
      this.modalData = item
      this.isStudentModal = true
    },
    hideStudentModal(val){
      if(val){
        this.getList()
      }
      this.isStudentModal = false
    },
    hideEditModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.editVisible = false
      this.isGrantEditModal = false
    },
    showCopyModal(item){
      this.modalData = item
      this.isCopyModal = true
    },
    hideCopyModal(){
      this.isCopyModal = false
    },
    deleteItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要删除"${item.coupon_title}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('courseCouponDeleteAction', { data: { coupon_id: item.coupon_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
  }
}
</script>
