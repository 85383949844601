<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="700px"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <a-input-number v-if="item.type === 'number'" style="width: 100%" v-decorator="[item.name, { rules: item.rules }]" />
        <a-date-picker v-if="item.type === 'date'" style="width: 100%" format="YYYY-MM-DD HH:mm" :show-time="{ format: 'HH:mm' }"  v-decorator="[item.name, { rules: item.rules }]"/>

        <a-select v-else-if="item.type === 'select'" placeholder="请选择"
           v-decorator="[item.name, { rules: item.rules }]">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>

        <a-radio-group v-else-if="item.type === 'radio'" :disabled="isEdit === 1?true:false" v-decorator="[item.name, { rules: item.rules }]" @change="handleRadio">
          <a-radio v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-radio>
        </a-radio-group>

        <a-radio-group v-else-if="item.type === 'radios'" :disabled="isEdit === 1?true:false" v-decorator="[item.name, { rules: item.rules }]">
          <a-radio v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-radio>
        </a-radio-group>

        <a-tree-select
            v-else-if="item.type === 'studio_ids'"
            v-decorator="[item.name, { rules: item.rules }]"
            tree-checkable
            allowClear
            :tree-data="seleteItems.studios"
            :maxTagCount='3'
            :maxTagTextLength='5'
            :replaceFields="{
              title:'studio_name',
              value: 'studio_id',
            }"
            :dropdownStyle="{maxHeight:'400px'}"
            :show-checked-strategy="SHOW_ALL"
            placeholder="请选择应用校区"
            @change="handleStudio"
          />

        <a-textarea v-else-if="item.type === 'textarea'" :rows="4"
           v-decorator="[item.name, { rules: item.rules }]"/>
      </a-form-item>
      <a-form-item label="关联可用" :label-col="labelCol" :wrapper-col="{ span: 18 }">
        <a-button v-if="radioValue == 2" icon="plus" type="dashed" @click="showCourseFeeModal">关联可用课程</a-button>
        <a-button v-if="radioValue == 1" icon="plus" type="dashed" @click="showProductModal">关联可用商品</a-button>
        <a-button v-if="radioValue == 3" icon="plus" type="dashed" @click="showMeetingModal">关联可用活动</a-button>
        <div>
          <a-tag v-show="radioValue == 2" @close="handleCloseClasses(index,2)" v-for="(item,index) in localCourseFee" :key='item.fee_id'>{{item.fee_name}}</a-tag>
          <a-tag v-show="radioValue == 1" @close="handleCloseClasses(index,1)" v-for="(item,index) in localProduct" :key='item.product_id'>{{item.product_name}}</a-tag>
          <a-tag v-show="radioValue == 3" @close="handleCloseClasses(index,3)" v-for="(item,index) in localMeeting" :key='item.meeting_id'>{{item.meeting_name}}</a-tag>
        </div>
      </a-form-item>
    </a-form>
    <meetingModal v-if="meetingVisible"/>
    <productModal v-if="productVisible"/>
    <courseFeeModal v-if="courseFeeVisible"/>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'coupon_title',
    label: '优惠券名称',
    type: 'input',
    rules: [{ required: true, message: '请输入优惠券名称!' }]
  },
  {
    name: 'coupon_type',
    label: '优惠券类型',
    type: 'radio',
    rules: [{ required: true, message: '请选择优惠券类型!' }],
    items: {
      data: 'couponTypes',
      label: 'label',
      value: 'value'
    }
  },
  {
    name: 'coupon_req_amount',
    label: '满多少',
    type: 'number',
    rules: [{ required: true, message: '请输入满多少!' }]
  },
  {
    name: 'coupon_dis_amount',
    label: '减多少',
    type: 'number',
    rules: [{ required: true, message: '请输入减多少!' }]
  },
  {
    name: 'coupon_limit',
    label: '发放数',
    type: 'number',
    rules: [{ required: true, message: '请输入发放数!' }]
  },
  {
    name: 'coupon_start_time',
    label: '开始有效期',
    type: 'date',
    rules: [{ required: true, message: '请选择开始有效期!' }]
  },
  {
    name: 'coupon_end_time',
    label: '结束时间',
    type: 'date',
    rules: [{ required: true, message: '请选择结束时间!' }]
  },
  {
    name: 'coupon_after_day',
    label: '有效天数',
    type: 'number',
    rules: []
  },
  {
    name: 'coupon_send_studio_ids',
    label: '适用校区',
    type: 'studio_ids',
    rules: [],
    items:{
      data:'studios',
      label: 'studio_name',
      value: 'studio_id'
    }
  },
  {
    name: 'coupon_can_exchange',
    label: '可以转赠',
    type: 'radios',
    rules: [],
    items: {
      data: 'couponExchange',
      label: 'label',
      value: 'value'
    }
  },
  {
    name: 'coupon_code',
    label: '优惠码',
    type: 'input',
    rules: []
  },
]
import C_ITEMS from '@/utils/items'
import moment from 'moment'
import { TreeSelect } from 'ant-design-vue'
const SHOW_ALL = TreeSelect.SHOW_ALL
import productModal from './productModal'
import meetingModal from './meetingModal'
import courseFeeModal from './courseFeeModal'
export default {
  name: 'EditModal',
  inject: ['parent'],
  provide() {
    return {
      parent: this
    }
  },
  props: {
    item: Object,
    isEdit:Number,
    isCopy:Number,
  },
  components: {
    productModal,
    meetingModal,
    courseFeeModal,
  },
  data() {
    return {
      SHOW_ALL,
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '新增优惠券',
      visible: false,
      loading: false,
      confirmLoading: false,
      courseFeeVisible: false,
      productVisible: false,
      meetingVisible: false,
      formData,
      form: this.$form.createForm(this),
      seleteItems: {
        couponTypes: C_ITEMS.couponTypes,
        couponExchange:[
          {label:'可以',value:1},
          {label:'不可以',value:0},
        ],
        studios:[],
      },
      localCourseFee:[],
      localProduct:[],
      localMeeting:[],
      radioValue:2,
      studio_ids:[]
    }
  },

  async created() {
    this.$ls.remove('localCourseFee')
    this.$ls.remove('localProduct')
    this.$ls.remove('localMeeting')
    this.visible = true
    this.getStudio()
    if (this.item) {
      this.loading = true
      let item = await this.getDetail()
      this.ModalTitle = '编辑优惠券'
      await this.$nextTick()
      let formFields = {}
      for (let d of formData) {
        if(d.name === 'coupon_start_time' || d.name === 'coupon_end_time'){
          formFields[d.name] = moment(item[d.name])
        }else{
          formFields[d.name] = item[d.name]
        }
      }
      this.radioValue = item.coupon_type
      this.localCourseFee = item.fee_id
      this.localProduct = item.product_id
      this.localMeeting = item.meeting_id
      this.$ls.set('localCourseFee', this.localCourseFee)
      this.$ls.set('localProduct', this.localProduct)
      this.$ls.set('localMeeting', this.localMeeting)
      this.form.setFieldsValue(formFields)
      this.loading = false
    }else{
      await this.$nextTick()
      this.form.setFieldsValue({coupon_type:2, coupon_can_exchange:0})
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async getDetail(){
      let res = await this.$store.dispatch('courseCouponDetailAction', { data: {coupon_id:this.item.coupon_id}})
      return res.data
    },
    async getStudio() {
      let res = await this.$store.dispatch('cCategoryStudiosAction', {})
      this.seleteItems.studios = res.data
    },
    async handleOk() {
      try {
        const { item, form } = this
        let params = await form.validateFields()

        if(this.radioValue == 1 && this.localProduct.length >0){
          let product_id = []
          this.localProduct.forEach(item=>{
            product_id.push(item.product_id)
          })
          params.product_id = product_id
        }

        if(this.radioValue == 2 && this.localCourseFee.length >0){
          let fee_id = []
          this.localCourseFee.forEach(item=>{
            fee_id.push(item.fee_id)
          })
          params.fee_id = fee_id
        }
        
        if(this.radioValue == 3 && this.localMeeting.length >0){
          let meeting_id = []
          this.localMeeting.forEach(item=>{
            meeting_id.push(item.meeting_id)
          })
          params.meeting_id = meeting_id
        }
        if (params) {
          if (item) {
            params.coupon_id = item.coupon_id
          }
          params.coupon_start_time = moment(params.coupon_start_time).format('YYYY-MM-DD HH:mm')
          params.coupon_end_time = moment(params.coupon_end_time).format('YYYY-MM-DD HH:mm')
          this.confirmLoading=true

        if(this.isCopy == 1){
          await this.$store.dispatch('courseCouponAddAction', { data: params })
            .then(res=>{
              this.$message.success('操作成功~')
              form.resetFields()
              this.parent.hideEditModal(1)
            })
            .catch(err=>{
              this.$message.warning('操作失败~')
              this.confirmLoading=false
            })
          }else{
            await this.$store.dispatch(item ? 'courseCouponUpdateAction' : 'courseCouponAddAction', { data: params })
            .then(res=>{
              this.$message.success('操作成功~')
              form.resetFields()
              this.parent.hideEditModal(1)
            })
            .catch(err=>{
              this.$message.warning('操作失败~')
              this.confirmLoading=false
            })
          }
        }
      } catch {
      }
    },
    handleStudio(e){
      this.studio_ids = e
    },
    handleCancel() {
      this.parent.hideEditModal(0)
    },
    showCourseFeeModal(){
      this.courseFeeVisible = true
    },
    hideCourseFeeModal(){
      let localCourseFee = this.$ls.get('localCourseFee') || []
      let list = []
      if(localCourseFee.length > 0){
        localCourseFee.forEach(item=>{
          list.push(item.fee_id)
        })
        list = Array.from(new Set(list))
        list.forEach((item,index)=>{
          localCourseFee.forEach(it=>{
            if(item == it.fee_id){
              list[index] = it
            }
          })
        })
        this.$ls.set('localCourseFee', list)
        this.localCourseFee = list
      }
      this.courseFeeVisible = false
    },
    showProductModal(){
      this.productVisible = true
    },
    hideProductModal(){
      let localProduct = this.$ls.get('localProduct') || []
      let list = []
      if(localProduct.length > 0){
        localProduct.forEach(item=>{
          list.push(item.product_id)
        })
        list = Array.from(new Set(list))
        list.forEach((item,index)=>{
          localProduct.forEach(it=>{
            if(item == it.product_id){
              list[index] = it
            }
          })
        })
        this.$ls.set('localProduct', list)
        this.localProduct = list
      }
      this.productVisible = false
    },
    showMeetingModal(){
      this.meetingVisible = true
    },
    hideMeetigModal(){
      let localMeeting = this.$ls.get('localMeeting') || []
      let list = []
      if(localMeeting.length > 0){
        localMeeting.forEach(item=>{
          list.push(item.meeting_id)
        })
        list = Array.from(new Set(list))
        list.forEach((item,index)=>{
          localMeeting.forEach(it=>{
            if(item == it.meeting_id){
              list[index] = it
            }
          })
        })
        this.$ls.set('localMeeting', list)
        this.localMeeting = list
      }
      this.meetingVisible = false
    },
    handleCloseClasses(index,radio){
      if(radio == 1){
        this.localProduct.splice(index,1)
        this.$ls.set('localProduct', this.localProduct)
      }else if(radio == 2){
        this.localCourseFee.splice(index,1)
        this.$ls.set('localCourseFee', this.localCourseFee)
      }else{
        this.localMeeting.splice(index,1)
        this.$ls.set('localMeeting', this.localMeeting)
      }
    },
    handleRadio(e){
      this.radioValue = e.target.value
    }
  }
}
</script>
