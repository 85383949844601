<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="确定" width="60%"
    @ok="handleOk" @cancel="handleCancel">
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <div class="clearfix table-tools">
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>活动名称</span>
              </template>
              <a-input allowClear v-model.trim="searchData.meeting_name" placeholder="活动名称" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>活动状态</span>
              </template>
              <a-select allowClear v-model="searchData.status" placeholder="请选择活动状态" style="width: 140px">
                <a-select-option v-for="(d, index) of meetingStatus" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <a-table :columns="columns" :dataSource="list" :bordered='false' :pagination="false" rowKey="meeting_id" size="small"
      :selection="{key: 'key'}" :customRow="clickRow"
      :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChanges}">
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>
        <template slot="status" slot-scope="text,record">
            <a-switch checkedChildren="进行中" unCheckedChildren="已下线" :checked="record.status == '1'"></a-switch>
        </template>
    </a-table>
    <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
  </a-modal>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '活动名称', dataIndex: 'meeting_name', key: '1' , scopedSlots: { customRender: 'meeting_name' },ellipsis:true},
  { title: '联系电话', dataIndex: 'meeting_hotline', key: '2' },
  { title: '活动价格', dataIndex: 'meeting_price', key: '4' ,align:'right',sorter:()=>{}},
  { title: '添加人', dataIndex: 'created_by', key: '8',align:'right' ,sorter:()=>{}},
  { title: '添加时间', dataIndex: 'created_at', key: '10',align:'right' ,sorter:()=>{}},
]
import moment from 'moment'
import C_ITEMS from '@/utils/items'
import tableMixins from '@/common/mixins/table'
export default {
  name: 'meetingModal',
  inject: ['parent'],
  props: {
    item: Object
  },

  data() {
    return {
      ModalTitle: '选择可用优惠券活动',
      visible: false,
      confirmLoading: false,
      columns,
      list: [],

      selectedRowKeys: [],
      localMeeting: [],
      searchData: {},
      loading: false,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      meetingStatus: C_ITEMS.meetingStatus,
    }
  },
  mixins:[tableMixins],
  created() {
    this.visible = true
    this.selectedRecord = this.$ls.get('localMeeting') || []
    this.selectedRecord.forEach(item=>{
        this.selectedRowKeys.push(item.meeting_id)
    })
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async getList() {
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_date') {
            obj['start_date'] = searchData[k][0]
            obj['end_date'] = searchData[k][1]
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('meetingCampaignAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    handleOk() {
      this.$ls.set('localMeeting', this.selectedRecord)
      this.parent.hideMeetigModal(1)
    },
    handleCancel() {
      this.parent.hideMeetigModal(0)
    },
    onSelectChanges(selectedRowKeys,record) {
      this.selectedRowKeys = selectedRowKeys
      if(this.selectedRecord.length === 0){
        this.selectedRecord = record
      }else{
        let arr = []
        let selectedRecord_id = []
        this.selectedRecord.forEach(item=>{
          selectedRecord_id.push(item.meeting_id)
        })
        record.forEach(item=>{
          if(selectedRecord_id.indexOf(item.meeting_id) === -1){
            arr.push(item)
          }
        })
        this.selectedRecord = [...this.selectedRecord,...arr]
      }
    },
    clickRow(record, index) {
        return {
            on: {
                click: () => {
                    let index = this.selectedRowKeys.indexOf(record.meeting_id)
                    if(index == -1){
                      this.selectedRowKeys.push(record.meeting_id)
                      this.selectedRecord.push(record)
                    }else{
                      this.selectedRowKeys.splice(index,1)
                      this.selectedRecord.splice(index,1)
                    }
                }
            }
        }
    },
  }
}
</script>