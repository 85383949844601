<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="确定" width="60%"
    @ok="handleOk" @cancel="handleCancel">
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <div class="clearfix table-tools">
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>商品名称</span>
              </template>
              <a-input allowClear v-model.trim="searchParams.search.product_name" placeholder="商品名称" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>商品状态</span>
              </template>
              <a-select allowClear v-model="searchParams.search.status" placeholder="请选择商品状态" style="width: 160px">
                <a-select-option v-for="(d, index) of productStatus" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <a-table :columns="columns" :dataSource="list" :bordered='false' :pagination="false" rowKey="product_id" size="small"
      :selection="{key: 'key'}" :customRow="clickRow"
      :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChanges}">
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>
        <template slot="image" slot-scope="text, record">
            <LImg :src="text" style="width: 44px; height: 44px;"/>
        </template>
        <template slot="status" slot-scope="text,record">
            <a-switch checkedChildren="售卖中" unCheckedChildren="已下架" :checked="record.status == '1'"/>
        </template>
        
    </a-table>
    <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
  </a-modal>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '商品图', width: '60px', dataIndex: 'product_image', key: 'name',scopedSlots: { customRender: 'image' }, },
  { title: '商品名称', dataIndex: 'product_name', key: '1' },
  { title: '商品分类', dataIndex: 'cat_name', key: '2' },
  { title: '销售价格￥', dataIndex: 'sale_price', key: '3' ,align:"center"},
  { title: '市场价￥', dataIndex: 'market_price', key: '4' ,align:"center"},
  { title: '销量', dataIndex: 'sale_count', key: '6' ,align:"center"},
  { title: '库存', dataIndex: 'product_instocks', key: '7' ,align:"center"},
]
import moment from 'moment'
import C_ITEMS from '@/utils/items'
import tableMixins from '@/common/mixins/table'
export default {
  name: 'productModal',
  inject: ['parent'],
  props: {
    item: Object
  },

  data() {
    return {
      ModalTitle: '选择可用优惠券商品',
      visible: false,
      confirmLoading: false,
      columns,
      list: [],

      selectedRowKeys: [],
      localProduct: [],
      
      loading: false,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      productStatus: C_ITEMS.productStatus,
    }
  },
  mixins:[tableMixins],
  created() {
    this.visible = true
    this.selectedRecord = this.$ls.get('localProduct') || []
    this.selectedRecord.forEach(item=>{
        this.selectedRowKeys.push(item.product_id)
    })
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async getList() {
      this.loading = true
      let res = await this.$store.dispatch('storeProductAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    handleOk() {
      this.$ls.set('localProduct', this.selectedRecord)
      this.parent.hideProductModal(1)
    },
    handleCancel() {
      this.parent.hideProductModal(0)
    },
    onSelectChanges(selectedRowKeys,record) {
      this.selectedRowKeys = selectedRowKeys
      if(this.selectedRecord.length === 0){
        this.selectedRecord = record
      }else{
        let arr = []
        let selectedRecord_id = []
        this.selectedRecord.forEach(item=>{
          selectedRecord_id.push(item.product_id)
        })
        record.forEach(item=>{
          if(selectedRecord_id.indexOf(item.product_id) === -1){
            arr.push(item)
          }
        })
        this.selectedRecord = [...this.selectedRecord,...arr]
      }
    },
    clickRow(record, index) {
        return {
            on: {
                click: () => {
                    let index = this.selectedRowKeys.indexOf(record.product_id)
                    if(index == -1){
                      this.selectedRowKeys.push(record.product_id)
                      this.selectedRecord.push(record)
                    }else{
                      this.selectedRowKeys.splice(index,1)
                      this.selectedRecord.splice(index,1)
                    }
                }
            }
        }
    },
  }
}
</script>