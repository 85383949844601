<template>
  <a-modal title="发放记录" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="52.5%" :footer="null"
    @ok="handleOk" @cancel="handleCancel">
        <div class="zcxy">
            <div class="clearfix table-tools">
            <div class="buttons">
            </div>
            <div class="search">
                <a-form layout='inline' @submit="searchList">
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>学员名称/手机号</span>
                        </template>
                        <a-input allowClear v-model.trim="searchParams.search.student_name" placeholder="学员名称/手机号" style="width: 160px"/>
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>优惠券状态</span>
                        </template>
                        <a-select allowClear v-model="searchParams.search.coupon_status" placeholder="优惠券状态" style="width: 160px">
                            <a-select-option v-for="(d, index) of couponStatus2" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                        </a-select>
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>发放人</span>
                        </template>
                        <a-select allowClear v-model="searchParams.search.created_by" placeholder="发放人" style="width: 160px" showSearch @search="handleSearch" :filterOption="filterOption">
                            <a-select-option v-for="(d, index) of staffList" :key="index" :value="d['employee_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
                    </a-form-item>
                </a-form>
            </div>
            </div>
            <div class="table">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <div class="table">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table size="small" :pagination="false" :bordered='false' rowKey="student_coupon_id"
                    :columns="columns" :dataSource="list"  @change="handleChange">
                    <template slot="index" slot-scope="text, record , index">
                    <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                    </template>
                    <template slot="action" slot-scope="text,record">
                    <!-- <a href="javascript:;" @click="showWithdraw(record)">撤回</a> -->
                    <a v-if="record.coupon_status" href="javascript:;" @click="showWithdraw(record)">撤回</a>
                    </template>
                </a-table>
            </div>
            </div>
            <div class="page">
            <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
            >
                <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                </template>
                </a-pagination>
            </div>
        </div>
  </a-modal>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '学员名称', dataIndex: 'student_name', key: '1' },
  { title: '开始时间', dataIndex: 'coupon_start_time', key: '3' },
  { title: '结束时间', dataIndex: 'coupon_end_time', key: '4' },
  { title: '优惠券状态', dataIndex: 'coupon_status_name', key: '2' ,align:"center",sorter:()=>{}},
  { title: '发放时间', dataIndex: 'created_at', key: 'created_at' ,align:"right"},
  { title: '发放账户', dataIndex: 'created_by', key: 'created_by' ,align:"right"},
  { title: '操作', key: 'operation', fixed: 'right', width: 90, scopedSlots: { customRender: 'action' },align:"right"},
]

    import C_ITEMS from '@/utils/items'
    import tableMixins from '@/common/mixins/table'
    export default {
        name:'studentModal',
        data() {
            return {
                loading: false,
                visible: false,
                confirmLoading: false,
                columns,
                list:[],
                staffList:[],
                couponStatus2: C_ITEMS.couponStatus2,
                pageParams: {
                    currentPage: 1,
                    pageCount: 50,
                    perPage: 10,
                    totalCount: 0
                },
            }
        },
        inject: ['parent'],
        mixins: [ tableMixins ],
        props: {
            item: Object
        },
        created () {
            this.visible = true
        },
        methods: {
            async getList(){
                this.loading = true
                this.searchParams.search.coupon_id = this.item.coupon_id
                let res = await this.$store.dispatch('courseCouponSendListAction', {data:this.searchParams})
                this.list = res.items
                this.pageParams = res._meta
                this.loading = false
            },
            async showWithdraw(item){
                let that = this
                this.$confirm({
                    title: `您确定撤回该优惠券？`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        let res = await that.$store.dispatch('courseCouponCancelCouponAction', {student_coupon_id:[item.student_coupon_id]})
                        if (res) {
                        that.getList()
                        that.$message.success('操作成功')
                        resolve(res)
                        }
                    }).catch(error => console.log(error))
                    }
                })
            },
            async getStaff(val){
                let obj = {q:val}
                let res = await this.$store.dispatch('searchAccountEmployeeAction', {params:obj})
                this.staffList = res.data
            },
            handleSearch(val){
                this.getStaff(val)
            },
            handleOk() {},
            handleChange(pagination, filters, sorter){
                if(sorter.order){
                    if(sorter.order === "ascend"){
                        this.searchParams['sort'] = `${sorter.field}`
                    }else{
                        this.searchParams['sort'] = `-${sorter.field}`
                    }
                    }else{
                    this.searchParams['sort'] = ''
                    }
                this.getList();
            },
            handleCancel(){
                this.parent.hideStudentModal()
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>