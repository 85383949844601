<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item v-if="canExcelexport">
            <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>学员名称/手机号</span>
              </template>
              <a-input allowClear v-model.trim="searchData.student_name" placeholder="学员名称/手机号" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>优惠券名称</span>
              </template>
              <a-input allowClear v-model.trim="searchData.coupon_title" placeholder="优惠券名称" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>优惠券状态</span>
              </template>
              <a-select allowClear v-model="searchData.coupon_status" placeholder="优惠券状态" style="width: 160px">
                <a-select-option v-for="(d, index) of couponStatus2" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>发放人</span>
              </template>
              <a-select allowClear v-model="searchData.created_by" placeholder="发放人" style="width: 160px" showSearch @search="handleSearch" :filterOption="filterOption">
                <a-select-option v-for="(d, index) of staffList" :key="index" :value="d['employee_id']">{{ d['filter_name'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item v-if="canIndex">
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="student_coupon_id"
        :columns="columns" :dataSource="list">
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>
        <template slot="coupon_status_name" slot-scope="text">
          <a-tag :color="text == '有效'?'green':'silver'">{{text}}</a-tag>
        </template>

        <template slot="action" slot-scope="text,record">
          <a v-if="record.coupon_status" href="javascript:;" @click="showWithdraw(record)">撤回</a>
        </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '学员名称', dataIndex: 'student_name', key: '1' },
  { title: '优惠券名称', dataIndex: 'coupon_title', key: 'name' },
  { title: '优惠券类型', dataIndex: 'coupon_type', key: 'coupon_type' },
  { title: '开始时间', dataIndex: 'coupon_start_time', key: '3' },
  { title: '结束时间', dataIndex: 'coupon_end_time', key: '4' },
  { title: '发放类型', dataIndex: 'coupon_take_type', key: 'coupon_take_type' ,align:"center"},
  { title: '有效状态', dataIndex: 'coupon_status_name', key: 'coupon_status_name' ,align:"center",scopedSlots: { customRender: 'coupon_status_name' }},
  { title: '发放时间', dataIndex: 'created_at', key: 'created_at' ,align:"right"},
  { title: '经办人', dataIndex: 'created_by', key: 'created_by' ,align:"right"},
  { title: '操作', key: 'operation', fixed: 'right', width: 90, scopedSlots: { customRender: 'action' },align:"right"},
]

import C_ITEMS from '@/utils/items'
import moment from 'moment'
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table'
export default {
  name: 'gjjl',

  data() {
    return {
      loading: false,
      exportLoading:false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,
      staffList:[],
      couponStatus2: C_ITEMS.couponStatus2,

      searchData: {},
      authType:['market','course_fee'],
      visible: false
    }
  },
  mixins: [ authority , tableMixins ],
  created () {
    this.getStaff()
  },
  methods: {
    closeSearch() {
      this.visible = false
    },
    handleSearch(val){
      this.getStaff(val)
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          obj[k] = searchData[k]
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('courseCouponSendListAction', {data:this.searchParams})
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    async showWithdraw(item){
      let that = this
      this.$confirm({
        title: `您确定撤回该优惠券？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('courseCouponCancelCouponAction', {student_coupon_id:[item.student_coupon_id]})
            if (res) {
              that.getList()
              that.$message.success('操作成功')
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    async getStaff(val){
        let obj = {q:val}
        let res = await this.$store.dispatch('searchAccountEmployeeAction', {params:obj})
        this.staffList = res.data
    },
    async toExport(){
      this.exportLoading = true
      let searchParams = {
        student_name:this.searchData.student_name,
        coupon_title:this.searchData.coupon_title,
        coupon_status:this.searchData.coupon_status,
      }
      let res = await this.$store.dispatch('courseCouponExportAction', searchParams)
      const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
      if ('download' in document.createElement('a')) { 
          const link = document.createElement('a')
          link.download = `发放记录.${moment().format('YYYY.MM.DD')}.xlsx`
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href) 
          document.body.removeChild(link)
      } else { //其他浏览器
          navigator.msSaveBlob(blob, fileName)
      }
      this.exportLoading = false
    }
  }
}
</script>
