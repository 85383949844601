import { render, staticRenderFns } from "./grantEditModal.vue?vue&type=template&id=1c9392a0&scoped=true&"
import script from "./grantEditModal.vue?vue&type=script&lang=js&"
export * from "./grantEditModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c9392a0",
  null
  
)

export default component.exports