<template>
    <div style="padding-bottom:15px;min-height:500px">
        <div class="clearfix table-tools">
            <div class="search">
                <a-form layout='inline' @submit="searchList">
                <a-form-item>
                    <a-tooltip placement="topLeft" >
                    <template slot="title">
                        <span>优惠券名称</span>
                    </template>
                    <a-input allowClear v-model.trim="searchParams.search.coupon_title" placeholder="优惠券名称" style="width: 160px"/>
                    </a-tooltip>
                </a-form-item>
                <a-form-item>
                    <a-tooltip placement="topLeft" >
                    <template slot="title">
                        <span>学员名称/手机号</span>
                    </template>
                    <a-input allowClear v-model.trim="searchParams.search.student_name" placeholder="学员名称/手机号" style="width: 160px"/>
                    </a-tooltip>
                </a-form-item>
                <a-form-item>
                    <a-radio-group :value="searchParams.search.coupon_status">
                        <a-radio-button :value="2" @click="handleTypeButChange(2,'coupon_status')">
                            已使用
                        </a-radio-button>
                    </a-radio-group>
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
                </a-form-item>
                </a-form>
            </div>
        </div>
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <LGraph v-if="modalData.data" :item="modalData" :loading="loading" :refresh="refresh" />
    </div>
</template>

<script>
    export default {
        name:'zzjl',
        data() {
            return {
                modalData:{},
                loading: false,
                searchParams:{
                    search:{}
                },
                refresh:1
            }
        },
        created () {
            this.getList()
        },
        methods: {
            async getList(num) {
                this.loading = true
                await this.$store.dispatch('courseCouponSendListGraphAction',this.searchParams)
                .then(res=>{
                    this.modalData = res.data
                    this.loading = false
                    if(num) this.refresh++
                })
                .catch(err=>{
                    this.loading = false
                })
            },
            handleTypeButChange(e,name){
                if(this.searchParams.search[name] == e){
                    this.searchParams.search[name] = undefined
                }else{
                    this.searchParams.search[name] = e
                }
                this.searchList()
            },
            searchList(e){
                e?e.preventDefault():''
                this.getList(1)
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>