<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>市场应用</a-breadcrumb-item>
      <a-breadcrumb-item>优惠券</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body students">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
        <!-- <a-tab-pane key="1">
          <span slot="tab">收费课程<a-badge :count="0" /></span>
          <sfkc v-if="activeKey==='1'"/>
        </a-tab-pane> -->
        <a-tab-pane key="1">
          <span slot="tab">优惠券<a-badge :count="0" /></span>
          <sfyhq v-if="activeKey==='1'"/>
        </a-tab-pane>
        <a-tab-pane key="3">
          <span slot="tab">发放记录<a-badge :count="0" /></span>
          <ffjl v-if="activeKey==='3'"/>
        </a-tab-pane>
        <a-tab-pane key="4">
          <span slot="tab">转赠记录<a-badge :count="0" /></span>
          <zzjl v-if="activeKey==='4'"/>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import sfkc from './sfkc/index'
import sfyhq from './sfyhq/index'
import ffjl from './ffjl/index'
import zzjl from './zzjl/index'

export default {
  components: {
    sfkc,
    sfyhq,
    ffjl,
    zzjl
  },
  data() {
    return {
      activeKey: '1'
    }
  },

  methods: {
    changeTab(key) {
      this.activeKey = key
    }
  }
}
</script>

