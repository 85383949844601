<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="700px"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <a-date-picker v-if="item.type === 'date'" style="width: 100%"  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>
        <a-select v-else-if="item.type === 'select'" placeholder="请选择"
           v-decorator="[item.name, { rules: item.rules }]">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>
        <a-textarea v-else-if="item.type === 'textarea'" :rows="4"
           v-decorator="[item.name, { rules: item.rules }]"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'course_id',
    label: '所属课程',
    type: 'select',
    rules: [{ required: true, message: '请输入所属课程!' }],
    items: {
      data: 'classCategorys',
      label: 'filter_name',
      value: 'course_id'
    }
  },
  {
    name: 'ware_name',
    label: '课件名称',
    type: 'input',
    rules: [{ required: true, message: '请输入课件名称!' }]
  },
  {
    name: 'course_link',
    label: '课件',
    type: 'input',
    rules: [{ required: true, message: '请输入课件!' }]
  },
]
export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '新增课程收费',
      visible: false,
      confirmLoading: false,
      formData,
      form: this.$form.createForm(this),
      seleteItems: {
        classCategorys: []
      }
    }
  },

  async created() {
    this.visible = true
    await this.$nextTick()
    await this.getClassCategory()
    if (this.item) {
      this.ModalTitle = '编辑课程收费'
      let item = await this.getDetail(this.item.fee_id)
      let formFields = {}
      for (let d of formData) {
        formFields[d.name] = item[d.name]
      }
      this.form.setFieldsValue(formFields)
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async getClassCategory() {
      let res = await this.$store.dispatch('searchCourseAction', {})
      this.seleteItems.classCategorys = res.data
    },
    async getDetail(fee_id) {
      let res = await this.$store.dispatch('courseWareDetailAction', {data: {fee_id}})
      return res.data
    },
    async handleOk() {
      this.confirmLoading=true;
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          if (item) {
            params.fee_id = item.fee_id
          }
          let res = await this.$store.dispatch(item ? 'courseWareUpdateAction' : 'courseWareAddAction', { data: params })
          if(res) {
            form.resetFields()
            this.parent.hideEditModal(1)
          }
        }
      } catch {
      }
			this.confirmLoading=false;
    },
    handleCancel() {
      this.parent.hideEditModal(0)
    }
  }
}
</script>
