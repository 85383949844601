<template>
  <a-modal title="发放优惠券" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="发放" width="700px"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label" :label-col="labelCol" :wrapper-col="wrapperCol">
            <div v-if="item.type === 'classes'">
                <a-button icon="plus" type="dashed" @click="shwoClassesModal">发放班级</a-button>
                <div>
                  <a-tag closable @close="handleCloseClasses(index)" v-for="(item,index) in classesList" :key='item.class_id'>{{item.class_name}}</a-tag>
                </div>
              </div>
            <a-select v-if="item.type === 'select'" mode="multiple"
                showSearch v-decorator="[item.name, { rules: item.rules }]"
                :defaultActiveFirstOption="false"
                :showArrow="false"
                :filterOption="false"
                @search="handleSearch"
                @change="handleChange"
                :notFoundContent="null"
              >
                <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">
                  <div>
                      <LImg style="width:24px;height:24px;border-radius:50%;margin-right:8px" :src="d['student_avatar']"/>
                      <span>{{ d[item.items.label] }}</span>
                  </div>
                </a-select-option>
              </a-select>
      </a-form-item>
    </a-form>
    <classesModal v-if="isClassesModal" :item="modalData" :isTab="2"/>
  </a-modal>
</template>

<script>
import classesModal from '@/views/students/zcxy/classesModal'
const formData = [
  {
    name: 'student_id',
    label: '发放学员',
    type: 'select',
    rules: [],
    items: {
      data: 'students',
      label: 'student_name',
      value: 'student_id'
    }
  },
  {
    name: 'class_id',
    label: '发放班级',
    type: 'classes',
    rules: [],
  },
]
    export default {
        name:'grantEditModal',
        provide() {
            return {
                parent: this
            }
        },
        inject: ['parent'],
        props: {
            item: Object
        },
        components:{ classesModal },
        data() {
            return {
                visible: false,
                confirmLoading: false,
                labelCol: { span: 5 },
                wrapperCol: { span: 15 },
                formData,
                form: this.$form.createForm(this),
                isClassesModal:false,
                classesList:[],
                modalData: {},
                seleteItems: {
                    students: []
                },
                process_all:0
            }
        },
        created(){
            this.visible = true
            this.$ls.remove('localClassesList')
        },
        methods: {
            async handleSearch(value) {
                let params = {
                    "q": value,
                    "all": 1
                }
                let res = await this.$store.dispatch('searchStudentsAction', { params })
                this.seleteItems.students = res.data
                this.$forceUpdate()
            },
            handleChange(value) {
                Object.assign(this, {
                    value,
                    data: [],
                    fetching: false,
                });
            },
            async handleOk() {
                const { item, form } = this
                let params = await form.validateFields()
                if(this.classesList.length == 0){
                if(params.student_id){
                    if(params.student_id.length ==0){
                        this.$message.warning('请选择学员或者班级!!')
                        return false
                    }
                    }else{
                    this.$message.warning('请选择学员或者班级!!')
                    return false
                    }
                }else{
                let class_id = []
                this.classesList.forEach(item=>{
                    class_id.push(item.class_id)
                })
                params.class_id = class_id
                }
                params.coupon_id = item.coupon_id
                params.process_all = this.process_all
                if(this.process_all === 1){
                    params.class_id = []
                }
                this.confirmLoading = true
                await this.$store.dispatch('courseCouponGrantAction', params)
                .then(res=>{
                    this.$message.success('操作成功!')
                    form.resetFields()
                    this.parent.hideEditModal(1)
                })
                .catch(err=>{
                    this.confirmLoading = false
                })
            },
            handleCancel(){
                this.parent.hideEditModal(0)
            },
            shwoClassesModal(){
                this.isClassesModal = true
            },
            hideClassesModal(type){
                if(type == 1){
                    this.process_all = 0
                    let classesList = this.$ls.get('localClassesList') || []
                    let list = []
                    if(classesList.length > 0){
                    classesList.forEach(item=>{
                        list.push(item.class_id)
                    })
                    list = Array.from(new Set(list))
                    list.forEach((item,index)=>{
                        classesList.forEach(it=>{
                        if(item == it.class_id){
                            list[index] = it
                        }
                        })
                    })
                    this.$ls.set('localClassesList', list)
                    this.classesList = list
                    }
                }else if(type == 2){
                    this.process_all = 1
                    this.classesList = [{class_id:-1,class_name:'全部'}]
                    this.$ls.remove('localClassesList')
                }
                this.isClassesModal = false
            },
            handleCloseClasses(index){
                this.classesList.splice(index,1)
                this.$ls.set('localClassesList', this.classesList)
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>